import React, { useState } from "react";
import Wrapper from "../components/Wrapper";
import Seo from "../shared/seo";
import Acnavbar from "../components/Acnavbar";
import OtherServicesCard from "../components/OtherServicesCard";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Col, Container, Row } from "react-bootstrap";
import calenderIcon from "../assets/images/calender-icon.webp";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import timeClock from "../assets/images/time-clock.webp";
import share from "../assets/images/share.webp";
import face from "../assets/images/face.webp";
import lin from "../assets/images/linked-in.webp";
import twiter from "../assets/images/twiter.webp";
import productcards from "../json/product-cards.json";
import Contact from "../components/Contact";
import Footer from "../components/footer";
import { Link, graphql } from "gatsby";
import { format } from "date-fns";
import BlogCard from "../components/BlogCard";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import useWindowLocation from "../customhooks/useWindowLocation";
import SocialMedia from "../components/SocialMedia";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function DetailBlog({ pageContext, data }: any) {
  const [tooltipText, setTooltipText] = useState("Click to copy link");
  const location = useWindowLocation();

  const copyLink = () => {
    navigator.clipboard.writeText(location);
    setTooltipText("Article URL Copied!");
    setTimeout(() => {
      setTooltipText("Click to copy link");
    }, 3000);
  };

  const tooltip = (
    <Tooltip id="tooltipText" className="tooltip-custom">
      {tooltipText}
    </Tooltip>
  );

  const options = {
    renderMark: {
      [MARKS.BOLD]: (
        text:
          | string
          | number
          | boolean
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      ) => <span className="">{text}</span>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (
        node: any,
        children:
          | string
          | number
          | boolean
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined
      ) => (
        <div className="">
          <p className="my-4">{children}</p>
        </div>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node: { data: { target: any } }) => {
        const { target } = node.data;
        const imageID = target.sys.id;
        const x = pageContext.pageData.body.references.find(
          (ref: { contentful_id: any }) => ref.contentful_id === imageID
        );

        if (x) {
          const imageUrl = x.file.url;
          const imageTitle = x.title;

          return (
            <div className="my-2 d-flex flex-column align-items-center">
              <LazyLoadImage
                effect="blur"
                src={imageUrl}
                className="img-fluid my-4"
                alt={imageTitle}
              />
            </div>
          );
        }
      },
    },
  };

  const Articles = data.allContentfulBlogArticle.edges.map(
    (node: any) => node.node
  );
  const filteredArticles = Articles.filter(
    (article: { title: any }) => article.title !== pageContext.pageData.title
  );
  
  function dateFormatBlog(date: any) {
    const date1 = new Date(date);
    const formattedDate = format(date1, "MMMM do, yyyy");
    return formattedDate;
  }

  return (
    <Wrapper>
      <Seo
        title= {pageContext.pageData.seoTitle}
        description= {pageContext.pageData.seoDescription}
      />
      <div>
        <Acnavbar home={false} pathname={"blogs"} logo={""} logoLogic={false} />
        <div className="service-background bg-cover">
          <Container className="d-flex flex-column align-items-center justify-content-center blogs-detail-height">
            <h3
              className="color-light-green text-uppercase fw-700 fnt-18 mb-4 text-center"
              style={{ letterSpacing: 2 }}
            >
              Blog
            </h3>
            <div className="product-header-background mb-4">
              <div className="fnt-lg-36  fnt-18 lh-26 lh-lg-35  fw-700  my-4 text-white text-center">
                {pageContext.pageData.title}
              </div>
            </div>
            <div className="d-flex  flex-wrap justify-content-center mb-4 ">
              {pageContext?.pageData?.categories?.map(
                (item: any, index: number) => (
                  <div className="blog-padding blog-details" key={index}>
                    <Link to={`/blogs/category/${item.slug}`} className="">
                      {item.title}
                    </Link>
                  </div>
                )
              )}
            </div>
            <div>
              <p className="text-white mb-0 pb-0 fw-400 fnt-14 fnt-lg-16 text-center mb-4">
                {pageContext.pageData.description.description}
              </p>
            </div>
            <div className="ms-3">
              <SocialMedia data={pageContext.pageData} />
            </div>
          </Container>
        </div>

        <div className="mx-auto">
          <Container className="d-flex flex-column w-lg-50 w-xl-45">
            <LazyLoadImage
              effect="blur"
              className="blog-details-top-image"
              src={pageContext.pageData.previewImage.file.url}
              alt={pageContext.pageData.previewImage.title}
            />
            <div className="pb-4">
              {renderRichText(pageContext.pageData.body, options)}
            </div>
          </Container>
        </div>

        <div className="bg-navyGreen px-lg-5 px-4">
          <Container className="py-lg-5 py-4  px-lg-7 mx-lg-9">
            <h1 className="fw-700 fnt-lg-32 mb-3 mb-lg-2 text-white text-center ">
              Our Services
            </h1>
            <Row xs={1} sm={1} md={3} lg={3} className="pe-0">
              {productcards.map((othercard: any) => (
                <Col key={othercard.id} className="pb-4">
                  <OtherServicesCard {...othercard} />
                </Col>
              ))}
            </Row>
          </Container>
        </div>
        <div className="py-4 py-lg-5">
          <Container>
            <h3 className="fw-700 fnt-18 mb-3  color-lightGreen text-center text-uppercase">
              Blogs
            </h3>
            <p className="fw-500 fnt-20 fnt-lg-32 text-center color-black">
              Related Blogs
            </p>
            <BlogCard data={filteredArticles} />
          </Container>
        </div>
        <Container className="">
          <Contact />
        </Container>
        <Footer />
      </div>
    </Wrapper>
  );
}

export default DetailBlog;

export const data = graphql`
  query PagesQuery($categorytitle: String!) {
    allContentfulBlogArticle(
      filter: { categories: { elemMatch: { title: { eq: $categorytitle } } } }
      sort: { fields: createdAt, order: ASC }
      limit: 3
    ) {
      edges {
        node {
          id
          title
          seoTitle
          timeToRead
          slug
          seoDescription
          description {
            description
          }
          createdAt
          author {
            designation
            name
            profileImage {
              file {
                contentType
                fileName
                url
              }
            }
          }
          previewImage {
            id
            title
            file {
              fileName
              url
            }
            gatsbyImageData(layout: FIXED)
          }
          categories {
            id
            title
            contentful_id
            slug
          }
          body {
            raw
            references {
              title
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;
